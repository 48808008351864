.MuiTextField-root{
    min-height: 46px !important;
    margin-right: 15px !important;
}

/* .MuiInputBase-input-MuiOutlinedInput-input {
    height: 0.4375em !important;
} */

.MuiSelect-root{
    margin-right: 0px !important;
    margin-bottom: 0px !important;
}

.MuiOutlinedInput-input{
    height: 10px !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall.css-6dxba9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
    margin-bottom: 5px !important;
    min-height: 44px !important;
    margin-right: 15px !important;
}

.MuiFormControl-root.css-1nrlq1o-MuiFormControl-root{
    margin-right: 0px !important ;
}